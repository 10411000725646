import { useAuth0 } from "@auth0/auth0-react";
import Appetize from './components/appetize';
import { Typography, CircularProgress } from '@okta/odyssey-react-mui';
import { Container } from '@mui/material';
import Cookies from 'universal-cookie';
import DemoContextProvider from './DemoContext';
import Nav from './components/nav'

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
  } = useAuth0();
  const cookies = new Cookies();

  if (isLoading) {
    return (
      <Container sx={{width: '100%', height: '100%', display:'flex', flexDirection:'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
          <Typography variant='support'>Loading</Typography>
      </Container>
    )
  }
  if (error) {
    window.location.replace(`${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENTID}&error=${error.name}&error_description=${error.message}`);
    return null
  }

  if (isAuthenticated) {
    return (
          <DemoContextProvider>
            <Container fluid sx={{display: 'flex', minHeight: '100vh', flexDirection: 'column'}}>
              <Nav />
              <Container sx={{display: 'flex', flex: '1 0 auto', flexGrow: 1, flexDirection: 'column', alignItems: 'center', justifyContent:'center'}}>
                <Appetize/>
              </Container>
            </Container>
          </DemoContextProvider>
    );
  } else {
    var searchParams = new URLSearchParams(document.location.search)
    if(searchParams.get('appId')){
      cookies.set('appId', searchParams.get('appId'), { path: '/' });
    }
    if(searchParams.get('demoName')){
      cookies.set('demoName', searchParams.get('demoName'), { path: '/' });
    }
    return loginWithRedirect();
  }
}

export default App;
