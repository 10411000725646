import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Box, Button, Status, Typography } from '@okta/odyssey-react-mui';
import { UserProfile } from '@okta/odyssey-react-mui/labs'
import { UserIcon } from '@okta/odyssey-react-mui/icons';
import { AppBar, Avatar, Toolbar, Container } from '@mui/material';
import './nav.css'
import { useDemoContext } from '../DemoContext';

const Nav = () => {
    const demoContext = useDemoContext()
    const {
      isLoading,
        isAuthenticated,
        user,
        loginWithRedirect,
        logout
      } = useAuth0();
    return (
          <AppBar position='relative' sx={{color: 'rgb(39, 39, 39)'}}>
            <Container maxWidth='lg'>
              <Toolbar disableGutters>
                <Box id='leftNav' sx={{ flexGrow: 1 }}>
                <Link to='/'>
                  <Box sx={{display:'flex', flexDirection:'row', alignItems: 'top'}}>
                    <Avatar src="https://cdn.demo.okta.com/images/okta-aura-black.svg" />
                    <Typography variant='h3'>mobile.demo.okta</Typography>
                  </Box>
                </Link>
                </Box>
                {!isLoading && isAuthenticated && (
                  <Box sx={{display:'flex', flexDirection:'row', alignContent: 'center', alignItems: 'center'}}>
                      <Status label={demoContext.demoName ? demoContext.demoName : 'No demo context'} severity={demoContext.demo ? 'success' : 'warning'}/>
                      <Button variant='floating' onClick={()=> logout()}>
                        <UserProfile
                            profileIcon={<UserIcon/>}
                            userName={user.name}
                            orgName={user['https://' + process.env.REACT_APP_DOMAIN + '/connection/'].toUpperCase()}
                          />
                      </Button>
                  </Box>
                )}
                {!isLoading && !isAuthenticated && (
                  <Button onClick={loginWithRedirect} label='login' variant='primary'/>
                )}
              </Toolbar>
            </Container>
          </AppBar>
    );
};
export default Nav;
