import React, { useState, useEffect } from 'react'
import './appetize.css'
import { useDemoContext } from '../DemoContext';
import { Button, CircularProgress, Typography } from '@okta/odyssey-react-mui';
import { FingerprintOutlined } from '@mui/icons-material';
import { Container } from '@mui/material';

const Appetize = () => {

    const demoContext = useDemoContext()
    const [demoParams, setDemoParams] = useState("")
    const [application, setApplication] = useState('xw3gc8ubrq6avpxmb8xaax4x6r')

    useEffect(() => {
        if(demoContext?.demo?.settings?.appetize_id){
            setApplication(demoContext.demo.settings.appetize_id)
        }
        if(demoContext?.demo){
            var demoParams = {...demoContext.demo.settings}
            demoParams.issuer = demoContext.demo.oidc_configuration?.issuer
            demoParams.client_id = demoContext.demo.oidc_configuration?.client_id
            setDemoParams(encodeURIComponent(JSON.stringify(demoParams)))
        }
        
    },[demoContext,demoContext.demo])

    if (demoContext.demo){
        return (     
            <div className="appetizeContainer">
                <iframe 
                    className="emulator" title="appetize" id="app_iframe"
                    src={`https://appetize.io/embed/${application}?device=pixel7pro&xdocMsg=true&osVersion=13.0&scale=auto&centered=both&params=${demoParams}`}>
                </iframe>
                <div className='deviceControls'>
                    <Button
                        startIcon={<FingerprintOutlined/>}
                        label='Simulate biometric match'
                        onClick={async () => {document.getElementById("app_iframe").contentWindow.postMessage('biometryMatch', '*')}}
                    />
                </div>
            </div>
        );
    } else {
        if(demoContext.error){
            return(
            <Container sx={{alignItems: 'center', justifyItems: 'center'}}>
                {demoContext.error}
            </Container>
            )
        } else{
            return(
                <Container sx={{alignItems: 'center', justifyItems: 'center'}}>
                    <CircularProgress/>
                    <Typography variant='support'>Loading</Typography>
                </Container>
            )
        }
    }
};
export default Appetize;
