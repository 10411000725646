import { createContext, useContext, useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import axios from "axios";

const DemoContext = createContext({demoName:{},setDemoName: ()=>{},demo:{}, error:{}})

const DemoContextProvider = ({children}) => {
    const cookies = new Cookies();
    const [demoName, setDemoName] = useState(cookies.get('demoName')||new URLSearchParams(document.location.search).get('demoName'))
    const [app] = useState(new URLSearchParams(document.location.search).get('appid')|| new URLSearchParams(document.location.search).get('appId')|| cookies.get('appid'))
    const [demo, setDemo] = useState()
    const [error, setError] = useState();


    useEffect(() => {
        if(app && demoName){
            axios.get(`${process.env.REACT_APP_DEMO_API_ENDPOINT}/bootstrap/${app}/${demoName}`,)
            .then(response => {setDemo(response.data)})
            .catch(err => {if(err.response.status === 404){setError(`Unable to locate application ${app} for demo ${demoName}`)}else{setError(`Encountered error code ${err.response.status}, if this problem persists reach out to Demo Engineering.`)}})
        }
    },[demoName,app])

    return (
        <DemoContext.Provider value={{demoName, setDemoName, demo, error}}>
            {children}
        </DemoContext.Provider>
    )
}
export const useDemoContext = ()=> useContext(DemoContext)
export default DemoContextProvider